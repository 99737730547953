import axios from "axios";
export default {
    namespaced: true,
    state: () => ({
        gateway_type: true,
        payment_type_id: 0,
        gateway : 0,
        is_cart  : false,
    }),
    getters: {
        gateway_type: (state) => {
            return state.gateway_type;
        },
        payment_type_id: (state) => {
            return state.payment_type_id;
        },
        gateway: (state) => {
            return state.gateway;
        },
        is_cart: (state) => {
            return state.is_cart;
        },
    },
    mutations: {},
    actions: {
        async fastKnetPayment({ commit,state }, payload) {

            return await axios.post("/fast-donate", {
                    gateway: payload.gateway,
                    payment_type_id: payload.payment_type_id,
                    gateway_type: payload.gateway_type,
                    amount: payload.amount,
                    name: "",
                    email: "",
                    mobile: "",
                    mk: payload.marketer,
                    project_id: payload.project.id,
                    gift: payload.gift,
                }
            );
        },
    },
};
