<template>
  <div>
    <div
      :data-count="items.count ? items.count : 0"
      id="dropdownShopCartArea"
      @click="getCart()"
      class="
        badget-count
        btn
        rounded-20
        position-relative
        align-items-center
        dropdown
      "
    >
      <i
        class="fas fa-shopping-cart fs-4 align-middle"
        aria-hidden="true"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      ></i>
      <div
        class="
          dropdown-menu dropdown-menu-sm-end dropdown-menu-md-start
          rounded-30
        "
        id="dropdownShopCartBox"
        aria-labelledby="dropdownShopCartArea"
      >
        <div
          class="
            shop-cart-total
            border-bottom border-1
            bg-white
            d-flex
            justify-content-between
            edc-color-primary
            p-2
            mb-2
          "
        >
          <div class="me-2">{{ $t("label.total") }}</div>
          <div class="ms-2 fs-5 d-flex">
            <span class="mx-2" lang="ar"
              >{{ items.total }} {{ items.currency }}</span
            >

            <span><i class="fas fa-money-bill"></i></span>
          </div>
        </div>

        <div class="shop-cart-project-list p-2 bg-gray-300" v-if="items.count > 0">
          <div v-for="item in items.items" :key="item.id">
              <router-link :to="{ path: '/products/' + item.project.slug }">
                  <h1 class="h6 text-truncate ">{{ item.project.title }}</h1>
              </router-link>
            <div
              class="shop-cart-project-item
                py-1
                d-flex
                justify-content-between
                mb-2
                border-bottom border-1
              "
            >
              
              <div class="me-3">
                <router-link :to="{ path: '/products/' + item.project.slug }">
                  <img
                    :src="item.project.image"
                    height="60"
                    class="rounded-10"
                    :alt="item.project.title"
                    srcset=""
                  />
                </router-link>
              </div>
              <div class="flex-fill me-3 text-end">
               

                <div class="d-flex justify-content-between align-items-center">
                  <div
                    class="
                      edc-border-secondary
                      fs-7
                      edc-color-secondary
                      p-2
                      rounded-pill
                      d-flex
                    "
                  >
                    <span class="ms-1">{{ $t("label.your_donate") }}</span>

                    {{ item.amount }} {{ item.project.currency }}
                  </div>
                  <div class="mx-3">
                    <button
                      class="btn edc-btn-secondary rounded-pill text-white"
                      type="button"
                      aria-label="remove from cart"
                      :data-id="item.id"
                      data-title="remove from cart"
                      @click="deleteFromCart(item.id)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="shop-cart-button p-2  pt-0 d-flex justify-content-between"
          v-if="items.count > 0"
        >
          <router-link
            to="/cart"
            class="
              btn
              edc-btn-primary
              text-white
              py-2
              px-3
              mt-2
              rounded-pill
              text-white
            "
          >
            <i class="fas fa-shopping-cart ms-2"></i>
            <span class="text-white">{{ $t("btn.show_cart") }}</span>
          </router-link>
            <button
                    @click.prevent="checkoutCart()"
                    class="btn
              edc-btn-primary
              text-white
              py-2
              px-3
              mt-2
              rounded-pill
              text-white"
            >
                <i class="fas fa-coins ms-2"></i>
                <span>{{ $t("btn.finish_donate") }}</span>
            </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  // camelCase in JavaScript
  name: "shop-cart",
  props: [],
  data() {
    return {};
  },
  methods: {
    ...mapActions("cart", ["getCart", "deleteItem"]),
    deleteFromCart(id) {
      this.$swal({
        title: this.$t("message.are_you_sure"),
        // text: this.$t("message.will_delete_project"),
        icon: "warning",
        buttons: {
          cancel: this.$t("message.redo"),
          delete: {
            text: this.$t("message.yes_delete"),
            value: "delete",
          },
        },
      }).then((result) => {
        switch (result) {
          case "delete":
            this.deleteItem({ id: id });
            this.$swal({
              icon: "success",
              title: this.$t("message.delete_success"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            break;
        }
      });
    },
      checkoutCart() {
          if (this.items.total <= 0 || this.items.total === undefined) {
              this.$swal({
                  icon: "error",
                  title: this.$t("message.must_insert_amount"),
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                      toast.addEventListener("mouseenter", this.$swal.stopTimer);
                      toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                  },
              });
              return false;
          }
          this.$root.$emit("doFastDonate", {
              amount: this.items.total,
              project: localStorage.getItem("edc_cart"),
              isCart: true,
          });
      },
  },
  created() {
    this.getCart();
  },
  computed: {
    ...mapGetters("cart", ["items"]),
  },
};
</script>
<style scoped>
.shop-cart-project-list{
    max-height: 300px !important;
    overflow: auto;
}
</style>