<template>
  <div id="app" dir="rtl">
    <!--header-->
    <header-layout></header-layout>
    <!--//header-->
    <!--main-->
    <main>
      <page-head></page-head>
      <router-view :key="$route.path"></router-view>
        <donate-popup></donate-popup>
    </main>
    <!--//main-->
    <!--footer-->
    <footer-layout></footer-layout>
    <!--//footer-->
  </div>
</template>
 <script>
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/css/style.css";
import "@/assets/css/donate.css";
import "@/assets/css/projects.css";

import HeaderLayout from "@/views/Layout/HeaderLayout.vue";
import PageHead from "@/views/Layout/PageHead.vue";
import FooterLayout from "@/views/Layout/FooterLayout.vue";
import ogImage from "@/assets/images/logo.svg";
import DonatePopup from "./components/DonatePopup.vue"
import {Tooltip} from "bootstrap";
export default {
  components: { HeaderLayout, PageHead,DonatePopup, FooterLayout },
mounted() {
  new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
  });
},
  metaInfo() {
    return {
      //script: [{ src: "/assets/js/custom.js", async: true, defer: true }],
      title: this.$t("app.name"),
      titleTemplate: "%s",
      meta: [
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          vmid: "description",
          name: "description",
          content:
            "لجنة الدعوة الإلكترونية هي لجنة تابعة لجمعية النجاة الخيرية تأسست عام 2012، وهي واحدة من أهم وأكبر اللجان الدعوية الإلكترونية المتخصصة في دول الخليج العربي والعالم الإسلامي. تتميز لجنة الدعوة الإلكترونية بالتنوع والتخصص في مشاريعها الدعوية من خلال توجيه هذه المشاريع حسب الجمهور والفئات المستهدفة في ضوء تنوع اللغات والأديان وتوزيعها عبر جغرافيا العالم. ",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("app.name"),
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:url", property: "og:url", content: window.location.href }, // here it is just ngrok for my test
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "لجنة الدعوة الإلكترونية هي لجنة تابعة لجمعية النجاة الخيرية تأسست عام 2012",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: window.location.origin + ogImage || "",
        },
        {
          vmid: "twitter:image:src",
          property: "twitter:image:src",
          content: ogImage || "",
        },
        { vmid: "og:image:width", property: "og:image:width", content: "768" },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: this.$t("app.name"),
        },
      ],
    };
  },
};
</script>
 
<style scoped>
</style>