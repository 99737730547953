import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import project_module from "../store/modules/project_module.js";
import auth_module from "../store/modules/auth_module.js";
import cart_module from "../store/modules/cart_module.js";
import news_module from "../store/modules/news_module.js";
import comment_module from "../store/modules/comment_module.js";
import site_module from "../store/modules/site_module";
import notify_module from "../store/modules/notify_module";
import testimonial_module from "../store/modules/testimonial_module";
import project_status_module from "../store/modules/project_status_module";
import reports_module from "./modules/reports_module";
import services_module from "./modules/services_module";
import payment_module from "@/store/modules/payment_module";

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		project: project_module,
		auth: auth_module,
		cart: cart_module,
		news: news_module,
		reports: reports_module,
		comment: comment_module,
		site: site_module,
		notify: notify_module,
		testimonial: testimonial_module,
		project_status: project_status_module,
		services : services_module,
		payment : payment_module,
	},
});
