import axios from "axios";
export default {
	namespaced: true,
	state: () => ({
		projects: [],
		project: {},
		project_statistics: {},
		get_project_statistics : true,
		isShowLoadMoreButton: false,
		total: 0,
		isShowLoading: true,
		isEmptyData: false,
		offset : 0
	}),
	getters: {
		projects: (state) => {
			return state.projects;
		},
		project: (state) => {
			return state.project;
		},
		project_statistics: (state) => {
			return state.project_statistics;
		},
		get_project_statistics:(state)=>{
			return state.get_project_statistics;
		},
		isShowLoadMoreButton: (state) => {
			return state.isShowLoadMoreButton;
		},
		total: (state) => {
			return state.total;
		},
		isEmptyData: (state) => {
			return state.isEmptyData;
		},
		isShowLoading: (state) => {
			return state.isShowLoading;
		},
		offset: (state) => {
			return state.offset;
		},

	},
	mutations: {
		SET_PROJECTS(state, payload) {
			for (let index = 0; index < payload.length; index++) {
				state.projects.push(payload[index]);
			}
		},
		RESET_PROJECTS(state, payload) {
			state.projects = payload;
		},
		SET_PROJECT(state, payload) {
			state.project = payload;
		},
		SET_PROJECT_STATISTICS(state, payload) {
			state.project_statistics = payload;
		},
		SET_GET_PROJECT_STATISTICS(state, payload){
			state.get_project_statistics = payload;
		},
		RESET_PROJECT(state) {
			state.project = {};
		},
		SET_IS_LOAD_MORE(state, payload) {
			state.isShowLoadMoreButton = payload;
		},
		SET_TOTAL(state, payload) {
			state.total = payload;
		},
		SET_IsEmptyData(state, payload) {
			state.isEmptyData = payload;
		},
		SET_IS_SHOW_LOADING(state, payload) {
			state.isShowLoading = payload;
		},
		SET_OFFSET(state, payload) {
			if(payload >= 0){
				state.offset = payload;
			}else{
				state.offset++;
			}

		},

	},
	actions: {
		async getProjects({ commit,state }, payload) {
			commit("SET_IS_SHOW_LOADING", true);
			commit("SET_OFFSET");
			payload.offset = state.offset;
			await axios
				.get("/projects", { params: payload })
				.then((response) => {
					if (response.status === 200) {
						if (response.data.projects.length > 0) {
							commit("SET_PROJECTS", response.data.projects);
							commit("SET_TOTAL", response.data.total);
							commit("SET_IS_LOAD_MORE", true);
						} else {
							commit("SET_IS_LOAD_MORE", false);
						}
					}
				})
				.catch((error) => {
					commit("SET_IsEmptyData", true);
				})
				.finally(() => {
					commit("SET_IS_SHOW_LOADING", false);
				});
		},
		async getProject({ commit }, payload) {
			return await axios.get("/project", { params: payload });
		},
		async getProjectStatistics({ commit }, payload) {
			 await axios.get("/project-statistics", { params: payload }).then((response) => {
				if (response.status === 200 && response.data.length > 0) {
					commit("SET_PROJECT_STATISTICS", response.data);
					commit("SET_GET_PROJECT_STATISTICS", true);
				}else{
					commit("SET_PROJECT_STATISTICS", {});
					commit("SET_GET_PROJECT_STATISTICS", false);
				}
			}).catch((error) => {
				 commit("SET_PROJECT_STATISTICS", {});
				 commit("SET_GET_PROJECT_STATISTICS", false);
				});
		},
		resetProjects({ commit }) {
			commit("RESET_PROJECTS", []);
			commit("SET_OFFSET",0);
		},
		resetStatistics({ commit }) {
			commit("SET_PROJECT_STATISTICS", []);
			commit("SET_GET_PROJECT_STATISTICS", false);
		},
		resetProject({ commit }) {
			commit("RESET_PROJECT");
		},
	},
};
