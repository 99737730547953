<template>
  <!-- Modal isShowPaymentTypeModal-->

    <div>
       
        <vue-modal-2 name="ShowPaymentTypeModal" :noHeader="true"
                     @on-close="closePaymentTypeModal"
                     :noFooter="true" style="z-index: 99991">
            <!--donate-modal-form-->
            <loading-page :titleClass="'h6'" class="my-2" :isShow="isLoading"></loading-page>
            <div>
                <div
                    class="
                    donate-modal-payments
                    px-3
                    gap-1
                  d-flex
                  flex-wrap
                   justify-content-between
                  "
                >
                    <div
                        v-if="paymentErrorMessage"
                        class="alert alert-danger col-12"
                        role="alert"
                    >
                        {{ paymentErrorMessage }}
                    </div>
                    <div v-if="site_info.myfatoorah_payment.length > 0 && site_info.payment.myfatoorah === 'active'"
                         v-for="mf_payment in site_info.myfatoorah_payment"
                         @click.prevent="setGateway(`myfatoorah`,mf_payment.id, mf_payment.type)"
                         class="
                      cursor-pointer
                      payment-type
                    
                      rounded-30
                      my-1
                      flex-fill
                      edc-border-primary
                      d-flex
                      justify-content-center
                      align-items-center
                    " :class="{
                      active:
                        payment_type_id === mf_payment.id,
                    }"
                    >
                        <label :for="`myfatoorah${mf_payment.id}`" class="cursor-pointer py-2 px-3 d-block w-100 text-center">
                            
                            <img
                                :src="mf_payment.image"
                                width="30px"
                                height="20px"
                                class="ms-2"
                                alt=""
                                srcset=""
                            />
                            <input
                                type="hidden"
                                name="payment_type_id"
                                :id="`myfatoorah${mf_payment.id}`"
                                :value="mf_payment.id"
                            />
                            <span class="fs-9 my-1 d-block fw-bold text-truncate">{{
                                    mf_payment.title
                                }}</span>
                        </label>
                    
                    </div>
                    <!-- end myfatoorah -->
                    <div
                        v-if="site_info.payment.knet === 'active'"
                        @click.prevent="setGateway('knet')"
                        class="
                      payment-type
                     cursor-pointer
                      rounded
                      edc-border-primary
                      d-flex
                      flex-fill
                      justify-content-between
                      align-items-center
                    "
                        :class="{
                      active:
                        gateway === 'knet',
                    }"
                    >
                        <label for="knet" class="cursor-pointer d-block w-100">
                            <input
                                type="radio"
                                v-model="gateway"
                                name="gateway"
                                value="knet"
                                id="knet"
                                autocomplete="off"
                            />
                            <img
                                src="@/assets/images/knet-small.png"
                                width="50px"
                                height="38px"
                                class="ms-2"
                                alt=""
                                srcset=""
                            />
                        </label>
                    </div>
                    <div
                        v-if="site_info.payment.visa === 'active'"
                        @click.prevent="setGateway('visa')"
                        class="
                      payment-type
                     cursor-pointer
                      rounded
                      edc-border-primary
                      flex-fill
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                        :class="{
                      active:
                        gateway === 'visa',
                    }"
                    >
                        <label for="visa" class="cursor-pointer d-block w-100">
                            <input
                                type="radio"
                                v-model="gateway"
                                name="gateway"
                                value="visa"
                                id="visa"
                                autocomplete="off"
                            />
                            <img
                                src="@/assets/images/visa-small.png"
                                width="50px"
                                height="27px"
                                class="ms-2"
                                alt=""
                                srcset=""
                            />
                        </label>
                    </div>
            </div>
            <div>
                <button
                    @click.stop="Donate"
                    type="button"
                    class="
                        w-75
                        m-2
                        mx-auto
                      btn
                      btn-success
           rounded-pill
                      py-2
                      text-center text-white
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                >
                    <i class="fas fa-coins mx-3"></i>
                    <span class="fs-7">{{ $t("btn.pay_now") }}</span>
                </button>
            </div>
            
            
            </div>
        
        </vue-modal-2>
        <!--// end payment model -->
        <vue-modal-2
                name="edc-donate-modal"
                @on-close="close"
                :noHeader="false"
                :noFooter="true"
                modalSize="md"
                :headerOptions="{title: $t('title.choose_payment_type')}"
                style="z-index: 9999"
        >
            
            <div>
                <loading :isShowLoading="isLoading"></loading>
                <div class="modal-dialog m-0">
                    <div class="modal-content edc-fast-donate-modal">
                        <div class="modal-body p-0">

                            <div class="donate-content my-2">
                                <!--donate-modal-project-->
                                <div
                                        v-if="project"
                                        class="donate-modal-project p-3 d-flex justify-content-start"
                                >
                                    <div class="donate-modal-project-img ms-2">
                                        <img
                                                v-if="project.image"
                                                :src="project.image.thumbnail"
                                                width="80"
                                                class="rounded-20"
                                                alt=""
                                                srcset=""
                                        />
                                    </div>
                                    <div class="donate-modal-project-details flex-fill">
                                        <h1
                                                v-if="project.title"
                                                class="donate-modal-project-title fw-bold mb-1 fs-6"
                                        >
                                            {{ project.title }}
                                        </h1>
                                        <div
                                                class="
                        donate-modal-project-price
                        fs-6
                        rounded-pill
                        d-block
                        fw-bold
                        edc-border-secondary
                        mt-3
                        edc-color-secondary
                        py-1
                        px-4
                        text-center
                      "
                                        >
                                            {{ $t("label.your_donate") }} :
                                            <span class="m-countup amount" :data-count="amount">{{
                                                amount
                                                }}</span>
                                            <span>KD</span>
                                        </div>
                                    </div>
                                </div>
                                <!--//donate-modal-project-->
                               
                                <!-- tabs -->
                                <div class="donate-modal-form mx-3" v-if="!isLogin">
                                    <ul
                                            class="edc-donation-tabs nav nav-tabs p-0 nav-justified"
                                            role="tablist"
                                    >
                                        <li class="nav-item" role="presentation">
                                            <a
                                                    class="nav-link py-2 active"
                                                    id="benefactor-tab"
                                                    data-bs-toggle="tab"
                                                    @click="clickTab('Benefactor')"
                                                    data-bs-target="#benefactor"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="benefactor"
                                                    aria-selected="true"
                                            >
                                                <i class="fas fa-heart"></i>
                                                {{ $t("label.Benefactor") }}
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a
                                                    class="nav-link py-2"
                                                    id="register-tab"
                                                    data-bs-toggle="tab"
                                                    @click="clickTab('Register')"
                                                    data-bs-target="#register"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="register"
                                                    aria-selected="false"
                                            >
                                                <i class="fas fa-handshake"></i>
                                                {{ $t("label.NewDonner") }}
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a
                                                    class="nav-link py-2"
                                                    id="login-tab"
                                                    data-bs-toggle="tab"
                                                    @click="clickTab('Login')"
                                                    data-bs-target="#login"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="login"
                                                    aria-selected="false"
                                            >
                                                <i class="fas fa-hand-holding-heart"></i>
                                                {{ $t("label.Login") }}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content p-2" id="donateTabContent">
                                        <div
                                                class="tab-pane fade show active"
                                                id="benefactor"
                                                role="tabpanel"
                                                aria-labelledby="benefactor-tab"
                                        >
                                            <div class="mb-2 alert alert-info fs-7 fw-normal">
                                                {{ $t("message.allow_connect") }}
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="mb-3 flex-fill">
                                                    <input
                                                            type="email"
                                                            v-model="dataInputs.email"
                                                            value=""
                                                            class="form-control"
                                                            id="email"
                                                            :placeholder="$t('label.email')"
                                                    />
                                                </div>
                                                <h6 class="or text-center mx-1">أو</h6>
                                                <div class="mb-3  flex-fill">
                                                    <input
                                                            type="tel"
                                                            v-model="dataInputs.mobile"
                                                            value=""
                                                            class="form-control"
                                                            id="mobile"
                                                            :placeholder="$t('label.mobile')"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div
                                                class="tab-pane fade"
                                                id="register"
                                                role="tabpanel"
                                                aria-labelledby="register-tab"
                                        >
                                            <div
                                                    v-if="errorsMessage.register"
                                                    class="alert alert-danger"
                                            >
                                                {{ errorsMessage.register }}
                                            </div>
                                            <div class="mb-3">
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="dataInputs.username"
                                                        value=""
                                                        name="user-name"
                                                        id="user-name"
                                                        :placeholder="$t('label.username')"
                                                />
                                            </div>
                                            <div class="mb-3">
                                                <input
                                                        type="email"
                                                        class="form-control"
                                                        v-model="dataInputs.email"
                                                        value=""
                                                        name="user-email"
                                                        id="user-email"
                                                        :placeholder="$t('label.email')"
                                                />
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center gap-1">
                                                <div class="mb-3 flex-fill">
                                                    <input
                                                            type="password"
                                                            class="form-control"
                                                            v-model="dataInputs.password"
                                                            value=""
                                                            name="user-password"
                                                            id="user-password"
                                                            :placeholder="$t('label.password')"
                                                    />
                                                </div>
                                                <div class="mb-3 flex-fill">
                                                    <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="dataInputs.mobile"
                                                            value=""
                                                            name="user-mobile"
                                                            id="user-mobile"
                                                            :placeholder="$t('label.mobile')"
                                                    />
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="mb-3 col-6">
                                                    <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="dataInputs.first_name"
                                                            value=""
                                                            name="user-first_name"
                                                            id="user-first_name"
                                                            :placeholder="$t('label.first_name')"
                                                    />
                                                </div>
                                                <div class="mb-3 col-6">
                                                    <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="dataInputs.last_name"
                                                            value=""
                                                            name="user-last_name"
                                                            id="user-last_name"
                                                            :placeholder="$t('label.last_name')"
                                                    />
                                                </div>
                                            </div>

                                            <div class="mb-0">
                                                <div class="d-grid gap-2">
                                                    <button
                                                            type="button"
                                                            @click="Register"
                                                            class="
                              btn
                              edc-btn-secondary
                              btn-block
                              flex-fill
                              rounded-pill
                              p-2
                              text-white
                            "
                                                    >
                                                        <i class="fas fa-user" aria-hidden="true"></i>
                                                        {{ $t("btn.register") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                                class="tab-pane fade"
                                                id="login"
                                                role="tabpanel"
                                                aria-labelledby="login-tab"
                                        >
                                            <div
                                                    v-if="errorsMessage.login"
                                                    class="alert alert-danger"
                                            >
                                                {{ errorsMessage.login }}
                                            </div>
                                            <div class="mb-3">
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="dataInputs.username"
                                                        value=""
                                                        name="user-login"
                                                        id="user-login"
                                                        :placeholder="$t('label.email_or_username')"
                                                />
                                            </div>
                                            <div class="mb-3">
                                                <input
                                                        type="password"
                                                        class="form-control"
                                                        v-model="dataInputs.password"
                                                        value=""
                                                        name="user-password"
                                                        id="user-password"
                                                        :placeholder="$t('label.password')"
                                                />
                                            </div>
                                            <div class="mb-0">
                                                <div class="d-grid gap-2">
                                                    <button
                                                            type="button"
                                                            @click="Login"
                                                            class="
                              btn
                              edc-btn-blue
                              btn-block
                              flex-fill
                              rounded-pill
                              p-2
                              text-white
                            "
                                                    >
                                                        <i class="fas fa-user" aria-hidden="true"></i>
                                                        {{ $t("btn.login") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--// tabs -->
                                <!-- user infomation -->
                                <div class="donate-modal-userinfo m-3" v-if="isLogin">
                                    <div class="d-flex align-items-center">
                                        <user-avatar :size="80"></user-avatar>
                                        <ul
                                                class="liststyle-none text-muted mx-0 px-2"
                                                data-aos="fade-left"
                                        >
                                            <li
                                                    class="mt-3 d-flex align-items-center"
                                                    data-aos="fade-right"
                                            >
                                                <i class="fa fa-user ms-1"></i>
                                                <span class="ms-2 d-inline">
                          {{ user.user_login }}
                        </span>
                                            </li>
                                            <li
                                                    class="mt-3 d-flexalign-items-center"
                                                    data-aos="fade-right"
                                            >
                                                <i class="fa fa-envelope ms-1"></i>
                                                <span class="ms-2 d-inline"
                                                >{{ user.user_email }}
                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!--// user infomation -->
                                <!--//donate-modal-form-->
                            </div>
                            <div class="donate-buttons p-2" v-if="isShowDonateButton">
                                <div
                                        class="
                    gap-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                                >
                                    <button
                                            type="button"
                                            @click="ShowPaymentTypeModal"
                                            class="
                      fast-donate
                      btn
                      edc-btn-primary
                      flex-fill
                      rounded-pill
                      py-3
                      text-center text-white
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                                    >
                                        <i class="fas fa-coins mx-3"></i>
                                        <span class="fs-6">{{ $t("btn.DonateNow") }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-modal-2>
    </div>
  
  <!-- //Modal -->
</template>
<script>
import Auth from "../auth.js";
import Loading from "../components/Loading";
import axios from "axios";
import {mapGetters} from "vuex";
import UserAvatar from "../components/UserAvatar.vue";
import {isIOS, isSafari, isMacOs} from 'mobile-device-detect';
import VueJsModal from "vue-js-modal"
import LoadingPage from "./LoadingPage.vue";

export default {
    // camelCase in JavaScript
    //Vue.component('donate-popup',
    name: "DonatePopup",
    components: {
        LoadingPage,
        Loading,
        UserAvatar,
        VueJsModal
    },
    props: [],
    data() {
        return {
            paymentErrorMessage: "",
            amount: 0,
            markater: "",
            gift: "",
            project: {
                image: {},
                type: Object,
                required: true,
            },
            isShow: true,
            isCart: false,
            gateway: "myfatoorah",
            gateway_type: "myfatoorah-knet",
            payment_type_id: 1,
            isLoading: false,
            isShowDonateButton: true,
            currentlyTab: "Benefactor",
            authInputs: {},
            dataInputs: {
                username: "",
                email: "",
                mobile: "",
                password: "",
                first_name: "",
                last_name: "",
            },
            isHideTabs: false,
            errorsMessage: {
                login: "",
                register: "",
            },
        };
    },
    computed: {
        ...mapGetters("auth", ["user", "isLogin"]),
        ...mapGetters("site", ["site_info"]),
    },
    methods: {
        clickTab(name) {
            this.currentlyTab = name;
        },
        isSupportApple() {
            return isSafari && (isIOS || isMacOs);
        },
        setGateway(gatewayName, payment_type_id = '', gateway_type = '') {
            this.gateway = gatewayName;
            this.payment_type_id = payment_type_id;
            this.gateway_type = gateway_type;
            this.Donate();
        },
        async Login() {
            this.isLoading = true;
            this.errorsMessage.login = "";
            let response;
            try {
                response = await axios.post("login", {
                    username: this.dataInputs.username,
                    password: this.dataInputs.password,
                });

                if (response.status === 200) {
                    Auth.setAUthData(response.data);
                    this.authInputs = Auth.getAUthData();
                    this.isHideTabs = false;
                    this.$root.$emit("updateAuthData", this.authInputs);
                    this.isLoading = false;
                }
            } catch (error) {
                if (error) {
                    this.errorsMessage.login = error.response.data.message;
                }
                this.isLoading = false;
            }
        },
        async Register() {
            this.isLoading = true;
            this.errorsMessage.register = "";
            let response;
            try {
                response = await axios.post("register", this.dataInputs);

                if (response.status === 200) {
                    Auth.setAUthData(response.data);
                    this.authInputs = Auth.getAUthData();
                    this.isHideTabs = false;
                    this.$root.$emit("updateAuthData", this.authInputs);
                }
                this.isLoading = false;
            } catch (error) {
                if (error) {
                    this.errorsMessage.register = error.response.data.message;
                }
                this.isLoading = false;
            }
        },
        async Donate() {
            this.isLoading = true;
            
            let payload = {};
            if (this.isCart === true) {
                payload = {
                    gateway: this.gateway,
                    gateway_type: this.gateway_type,
                    payment_type_id: this.payment_type_id,
                    amount: this.amount,
                    name: this.dataInputs.username,
                    email: this.dataInputs.email,
                    mobile: this.dataInputs.mobile,
                    mk: this.$route.query.mk,
                    project_id: this.project,
                    gift: this.gift,
                };
            } else {
                payload = {
                    gateway: this.gateway,
                    payment_type_id: this.payment_type_id,
                    gateway_type: this.gateway_type,
                    amount: this.amount,
                    name: this.dataInputs.username,
                    email: this.dataInputs.email,
                    mobile: this.dataInputs.mobile,
                    mk: this.$route.query.mk,
                    project_id: this.project.id,
                    gift: this.gift,
                };
            }
            await axios
                .post("fast-donate", payload)
                .then((response) => {
                    console.log("response", response);
                    if (response.data.status === "success") {
                        location.href = response.data.url;
                    } else {
                        this.isLoading = false;
                        this.$swal({
                            icon: "error",
                            title: response.data.msg,
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                            },
                        });
                    }
                })
                .catch((error) => {
                    //console.log("Donate" + error);
                    this.$swal({
                        icon: "error",
                        title: error.message,
                    });
                }).finally(()=>{
                this.isLoading = false;
                });
        },
        ShowPaymentTypeModal() {
            this.$vm2.open("ShowPaymentTypeModal");
        },
        
        closePaymentTypeModal() {
            this.$vm2.close("ShowPaymentTypeModal");
        },
        userAuthData() {
            if (this.isLogin) {
                this.authInputs = this.user;
            }
        },
        open() {
            this.$vm2.open("edc-donate-modal");
        },
        close() {
            this.$vm2.close("edc-donate-modal");
        },
    },
    created() {
        //console.log("navigator.userAgent",{isIOS,isSafari,isChrome,isMacOs});
        this.userAuthData();
    },
    mounted() {
        //do event update Header ShopCart
        this.$root.$on("doFastDonate", (data) => {
            // here you need to use the arrow function
            this.amount = data.amount;
            this.project = data.project;
            this.markater = data.mk;
            this.gift = data.gift;
            this.isShow = false;
            if (data.isCart) {
                this.isCart = data.isCart;
            }
            this.open();
        });
        this.$root.$on("authLogout", (isLogout) => {
            // here you need to use the arrow function
            if (isLogout) {
                this.isHideTabs = false;
                this.authInputs = {};
            }
        });
        this.isHideTabs = this.isLogin;
    },

    watch: {
        dataInputs: {
            handler(newValue) {
                // if (newValue.mobile !== "" || newValue.email !== "") {
                //   this.isShowDonateButton = true;
                // } else {
                //   this.isShowDonateButton = true;
                // }
            },
            deep: true,
        },
        isLogin: function (newValue) {
            this.isHideTabs = newValue;
        },
        isHideTabs: function (newValue) {
            this.isShowDonateButton = newValue;
        },
        site_info: {
            handler(newValue) {
                /*if (newValue.payment.knet === "active") {
                  this.gateway = "knet";
                } else if (newValue.payment.visa === "active") {
                  this.gateway = "visa";
                } else if (newValue.payment.myfatoorah === "active") {
                  this.gateway = "myfatoorah";
                } else {
                  this.gateway = "";
                }*/
                if (
                    newValue.payment.knet !== "active" &&
                    newValue.payment.visa !== "active" &&
                    newValue.payment.myfatoorah !== "active"
                ) {
                    this.isShowDonateButton = false;
                    this.paymentErrorMessage = this.$t(
                        "message.no_any_payment_is_active"
                    );
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.cursor-pointer{
    cursor: pointer;
}
@media only screen
and (min-device-width: 375px)
and (max-device-width: 514px)
and (min-device-height: 375px)
and (max-device-height: 679px) {
    body {
        background-color: #ccc !important;
    }

    .donate-content {
        max-height: 300px !important;
        overflow: auto !important;
    }
}
</style>
